import React from 'react';

import Carousel from '../../../Components/Carousel/Carousel';
import Image from '../../../Components/Image/Image';
import Content from './Content';

import MOBILE_IMG_1_PNG from '../../../Assets/Features/img1.png';
import MOBILE_IMG_1_WEBP from '../../../Assets/Features/img1.webp';
import MOBILE_IMG_2_PNG from '../../../Assets/Features/img2.png';
import MOBILE_IMG_2_WEBP from '../../../Assets/Features/img2.webp';
import MOBILE_IMG_3_PNG from '../../../Assets/Features/img3.png';
import MOBILE_IMG_3_WEBP from '../../../Assets/Features/img3.webp';
import MOBILE_IMG_4_PNG from '../../../Assets/Features/img4.png';
import MOBILE_IMG_4_WEBP from '../../../Assets/Features/img4.webp';
import MOBILE_IMG_5_PNG from '../../../Assets/Features/img5.png';
import MOBILE_IMG_5_WEBP from '../../../Assets/Features/img5.webp';
import MOBILE_IMG_6_PNG from '../../../Assets/Features/img6.png';
import MOBILE_IMG_6_WEBP from '../../../Assets/Features/img6.webp';

import {
  FEATURES_TEXT_14,
  FEATURES_TEXT_13,
  FEATURES_TEXT_12,
  FEATURES_TEXT_11,
  FEATURES_TEXT_10,
  FEATURES_TEXT_9,
  FEATURES_TEXT_8,
  FEATURES_TEXT_7,
  FEATURES_TEXT_6,
  FEATURES_TEXT_5,
  FEATURES_TEXT_4,
  FEATURES_TEXT_3,
  FEATURES_TEXT_2,
  FEATURES_TEXT_1
} from '../../../Constants';

import styles from './feature.module.css';

const FEATURES_CONTENT_LEFT = [
  {
    id: 1,
    heading: FEATURES_TEXT_3,
    info: FEATURES_TEXT_4,
    icon: 'lock'
  },
  {
    id: 2,
    heading: FEATURES_TEXT_5,
    info: FEATURES_TEXT_6,
    icon: 'bell'
  },
  {
    id: 3,
    heading: FEATURES_TEXT_7,
    info: FEATURES_TEXT_8,
    icon: 'plus'
  }
];

const FEATURES_CONTENT_IMAGE = [
  {
    pic: MOBILE_IMG_1_PNG,
    pic_webp: MOBILE_IMG_1_WEBP
  },
  {
    pic: MOBILE_IMG_2_PNG,
    pic_webp: MOBILE_IMG_2_WEBP
  },
  {
    pic: MOBILE_IMG_3_PNG,
    pic_webp: MOBILE_IMG_3_WEBP
  },
  {
    pic: MOBILE_IMG_4_PNG,
    pic_webp: MOBILE_IMG_4_WEBP
  },
  {
    pic: MOBILE_IMG_5_PNG,
    pic_webp: MOBILE_IMG_5_WEBP
  },
  {
    pic: MOBILE_IMG_6_PNG,
    pic_webp: MOBILE_IMG_6_WEBP
  }
];

const FEATURES_CONTENT_RIGHT = [
  {
    id: 4,
    heading: FEATURES_TEXT_9,
    info: FEATURES_TEXT_10,
    icon: 'download'
  },
  {
    id: 5,
    heading: FEATURES_TEXT_11,
    info: FEATURES_TEXT_12,
    icon: 'code'
  },
  {
    id: 6,
    heading: FEATURES_TEXT_13,
    info: FEATURES_TEXT_14,
    icon: 'users'
  }
];

class Features extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      iconNum: null
    };

    this.ref = React.createRef();
  }

  setIconNum = (iconNum) => {
    this.ref.current.goToSlide(iconNum);
  };

  setIconActive = (iconNum) => {
    this.setState({
      iconNum
    });
  };

  render() {
    return (
      <div data-testid="features" id="features">
        <div className={styles.container}>
          <div className={styles.header}>
            <p className={`${styles.large_heading} heading_text`}>
              {FEATURES_TEXT_1}
            </p>
            <p className={`body_text`}>{FEATURES_TEXT_2}</p>
          </div>

          <div className={styles.content}>
            <div className={styles.content_left}>
              {FEATURES_CONTENT_LEFT.map((content, index) => (
                <Content
                  key={`${content}+${index}`}
                  id={content.id}
                  heading={content.heading}
                  info={content.info}
                  icon={content.icon}
                  side="left"
                  onClick={this.setIconNum}
                  iconActive={this.state.iconNum === content.id}
                />
              ))}
            </div>

            <div className={styles.slider}>
              <Carousel
                indicators={false}
                controls={false}
                interval={2000}
                ref={this.ref}
                onChange={this.setIconActive}>
                {FEATURES_CONTENT_IMAGE.map((item, index) => (
                  <div className={styles.slide} key={`${item}+${index}`}>
                    {/* <img
                      className={styles.slide_image}
                      src={image}
                      alt="mobile_image"
                    /> */}
                    <Image
                      className={styles.slide_image}
                      src={item.pic}
                      webpImage={item.pic_webp}
                      alt="mobile_image"
                    />
                  </div>
                ))}
              </Carousel>
            </div>

            <div className={styles.content_right}>
              {FEATURES_CONTENT_RIGHT.map((content, index) => (
                <Content
                  key={index}
                  id={content.id}
                  heading={content.heading}
                  info={content.info}
                  icon={content.icon}
                  side="right"
                  onClick={this.setIconNum}
                  iconActive={this.state.iconNum === content.id}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Features;
