import React from 'react';
import Icon from '../../../Components/Icon/Icon';
import styles from './content.module.css';

const Content = (props) => (
  <div className={styles.container}>
    <div
      className={`${styles.text_left} ${
        props.side === 'right' ? styles.text_right : ''
      }`}>
      <span className={`sm_heading_text`}>{props.heading}</span>
      <span className={`body_text`}>{props.info}</span>
    </div>

    <div
      className={`center ${styles.icon_left} ${
        props.side === 'right' ? styles.icon_right : ''
      }`}>
      <div
        className={`${styles.icon} center ${
          props.iconActive === true ? styles.active : null
        }`}
        onClick={() => props.onClick(props.id)}>
        <Icon icon={props.icon} size="lg" />
      </div>
    </div>
  </div>
);

export default Content;
