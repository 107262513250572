import React from 'react';

import Icon from '../Icon/Icon';

import styles from './carousel.module.css';

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activeIndex: 0,
      transition: 'transform 500ms ease-in-out'
    };

    this.interval = '';

    this.callOnChange(1);
  }

  componentDidMount() {
    let newSlides = React.Children.toArray(this.props.children);

    const firstElement = newSlides[0];
    const lastElement = newSlides[newSlides.length - 1];
    newSlides = [lastElement, ...newSlides, firstElement];

    this.setState({
      data: newSlides,
      activeIndex: 1
    });

    this.interval = setInterval(this.nextSlide, this.props.interval);
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  callOnChange = (currentIndex) => {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(currentIndex);
    }
  };

  nextSlide = async () => {
    await this.setState((prevState) => {
      const { activeIndex, data } = prevState;

      if (activeIndex === data.length - 2) {
        setTimeout(() => {
          this.setState({
            activeIndex: 1,
            transition: 'none'
          });
        }, 500);

        this.callOnChange(1);
        return {
          activeIndex: activeIndex + 1,

          transition: this.props.transition
            ? this.props.transition
            : 'transform 500ms ease-in-out'
        };
      }

      this.callOnChange(activeIndex + 1);
      return {
        activeIndex: activeIndex + 1,

        transition: this.props.transition
          ? this.props.transition
          : 'transform 500ms ease-in-out'
      };
    });
  };

  prevSlide = () => {
    this.setState((prevState) => {
      const { activeIndex, data } = prevState;

      if (activeIndex === 1) {
        setTimeout(() => {
          this.setState({
            transition: 'none',
            activeIndex: data.length - 2
          });
        }, 500);

        this.callOnChange(data.length - 2);
        return {
          activeIndex: activeIndex - 1,
          transition: this.props.transition
            ? this.props.transition
            : 'transform 500ms ease-in-out'
        };
      }

      this.callOnChange(activeIndex - 1);
      return {
        activeIndex: activeIndex === 1 ? data.length - 2 : activeIndex - 1,
        transition: this.props.transition
          ? this.props.transition
          : 'transform 500ms ease-in-out'
      };
    });
  };

  goToSlide = (index) => {
    this.callOnChange(index);
    this.setState({
      activeIndex: index
    });
  };

  render() {
    const { indicators = true, controls = true } = this.props;
    const { activeIndex, data, transition } = this.state;

    return (
      <div className={`${styles.carousel}`}>
        {/* Indicators */}
        {indicators === true ? (
          <ul className={styles.indicators}>
            {this.props.children.map((slide, index) => (
              <li
                key={index}
                className={
                  index === activeIndex ? styles.active_indicator : null
                }
                onClick={() => this.goToSlide(index)}></li>
            ))}
          </ul>
        ) : null}

        {/* Prev Button */}
        {controls === true ? (
          <div
            className={`${styles.controls} ${styles.control_prev}`}
            onClick={this.prevSlide}>
            <Icon icon="angle-left" size="3x" />
          </div>
        ) : null}

        {/* Next Button */}
        {controls === true ? (
          <div
            className={`${styles.controls} ${styles.control_next}`}
            onClick={this.nextSlide}>
            <Icon icon="angle-right" size="3x" />
          </div>
        ) : null}

        {/* Slides */}
        {data.map((child, index) => {
          return React.cloneElement(child, {
            style: {
              transform: `translateX(${-activeIndex * 100}%)`,
              transition
            },
            key: `${child}-${index}`
          });
        })}
      </div>
    );
  }
}
export default Carousel;
